<template>
    <v-dialog max-width="500px" v-model="show">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>New Supplier</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-form ref="mainForm">
                            <v-text-field 
                                label="Code" 
                                outlined
                                dense
                                v-model="form.supplier_code"
                                v-if="show" autofocus  
                            ></v-text-field>
                            <v-text-field 
                                label="Name" 
                                outlined
                                dense
                                v-model="form.supplier_name"  
                            ></v-text-field>
                            
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
//import axios from 'axios';
import ApSupplierService from "@/services/ApSupplierService"
import moment from 'moment'
export default {
    name: "DlgSupplierAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
      
    },      
    data() {
    return {
        
      message: "",
      customer_id:0,
      form:{
            supplier_code:"",
            supplier_name:"",
            status:"ACTIVE",            
          },
        dataList:[],
        dpicker:{menu1: false,menu2: false,},
        respCode:0,
    //   rules: {
    //         required: value => !!value || 'Required.',
    //         counter: value => value.length == 6 || 'Need 6 digit',
    //         digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
    //   },
    };
  },
  async created(){
      this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
      this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');
  },
  mounted(){
        this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
        this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

  },
  methods: {
    dlgShow(){
    },
    dlgClose(){
        console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.c_code = "";
            this.form.c_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },
    async save(){
        if(this.$refs.mainForm.validate()) {

            console.log(this.form);
            try{
                ApSupplierService.insert_supplier(this.form).then(() => {
                    //console.log(res);
                    this.$refs.dlgMsg.showDlgBox(true,'success','New Supplier','Success.');
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Supplier',e.response.data.error_message);
                })
                ;
            } catch(e) {
                this.loadingFlag = false
                this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
                
            }             
        }
    },

  }
};

</script>
